import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';

function objectsAreNotEqual(obj1, obj2) {
  if (obj1 === undefined || obj2 === undefined) {
    return true;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return true;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }

  return false;
}

const ArrayCellRenderer = (props) => {
  const { isLoading, prevRowData } = props.context;
  const { value, data } = props;
  const [isCellBlinking, setIsCellBlinking] = useState(false);

  useEffect(() => {
    if (prevRowData && prevRowData.length > 0) {
      const prevData = prevRowData.find((row) => row.id === data.id);

      if (objectsAreNotEqual(data, prevData)) {
        setIsCellBlinking(true);
        const timer = setTimeout(() => setIsCellBlinking(false), 10000);
        return () => clearTimeout(timer);
      }
    }
  }, [data, prevRowData]);

  if (isLoading?.includes(data?.id)) {
    return (
      <div className="loading-spin">
        <Loading size="small" />
      </div>
    );
  } else {
    return (
      <div className={isCellBlinking ? 'gradient-animated-text' : ''}>
        <ul>
          {Array.isArray(value) ? (
            value.map((item, index) => <li key={index}>{item}</li>)
          ) : (
            <li>{value}</li>
          )}
        </ul>
      </div>
    );
  }
};

export default ArrayCellRenderer;
