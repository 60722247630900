import React, { useState, useEffect, useRef } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { set } from 'lodash';

export default function HTMLEditor({ value, onChange, placeholder }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isFocused, setIsFocused] = useState(false);
  const isMounted = useRef(false); // To prevent reinitializing on the first render

  useEffect(() => {
    if (isFocused) {
      return;
    }
    if (value && !isMounted.current) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    } else if (isMounted.current) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [value]);

  useEffect(() => {
    isMounted.current = true; // Mark component as mounted after the initial render
  }, []);

  const save = () => {
    setIsFocused(false)
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContentState = draftToHtml(rawContentState);
    onChange(htmlContentState); // Update parent component's state
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        placeholder={placeholder}
        wrapperClassName="draft-html-editor-wrapper"
        editorClassName="draft-html-editor"
        //onEditorStateChange={onEditorStateChange}
        onEditorStateChange={setEditorState}
        onFocus={() => setIsFocused(true)}
        onBlur={() => save()}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    </div>
  );
}
