import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import _ from 'lodash';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';

const Table = (props) => {
  const { listTitle, List, setList, columnDefs, rowData, contextMenuGeneration, isLoading } = props;

  const [errors, setErrors] = useState({});
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [agGridContext, setAgGridContext] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const prevRowDataRef = useRef();

  const needsSuppressRowTransform = useMemo(() => {
    return columnDefs.some(
      (col) => col.cellRendererFramework === DraggableListCellRenderer
    );
  }, [columnDefs]);
  
  useEffect(() => {
    setAgGridContext({ isLoading: isLoading,  prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = List;  // <---- Should this be rowData?
  }, [List, isLoading]); 

  const baseColumnConfig = {
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  }

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    
    params.api.sizeColumnsToFit();
  }, []);

  const onCellValueChanged = useCallback(params => {
    setList({ type: 'update', payload: params.data });
  }, [setList]);

  const onCellEditingStarted = useCallback(() => {
    setIsEditing(true);
  }, []);
  
  const onCellEditingStopped = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onCellFocused = useCallback((params) => {
    if (!isEditing) {
      gridApi.stopEditing();
    }
  }, [isEditing, gridApi]);

  const addNewRow = () => {
    const newRow = {
      id: generateuniqueIshId(),
      name: `${listTitle} ${Array.isArray(List) ? List.length + 1 : 1}`,
      designCriteria: '',
      priority: ''
    };
    setList({type: 'prepend', payload: [newRow] });
    return newRow;
  };

  const DeleteRowButtonRenderer = ({ api, node, data }) => {
    const handleDelete = () => {
      const updatedDesignCriteria = List.filter(activity => activity.id !== data.id);
      setList({ type: 'overwrite', payload: updatedDesignCriteria });
  
      setTimeout(() => {
        api.setRowData(updatedDesignCriteria);
      }, 0);
    };
  
    return (
      <span onClick={handleDelete}>
        <SVG
          icon="delete.svg#delete"
          iconWidth={24}
          iconHeight={24}
          iconClassName="button__icon button__icon-red"
        />
      </span>
    );
  };

  const onCellContextMenu = (event) => {
    setContextMenu({
      x: event.event.pageX,
      y: event.event.pageY,
      rowData: event.data,
    });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const onContextMenu = (e) => {
    e.preventDefault();
  };  

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newData = [];
    data.rows.forEach((row) => {
      let dc = {
        id: generateuniqueIshId(),
        name: data.name,
        designCriteria: row[data.designCriteria],
        priority: row[data.priority],
      }
      if (newData.find(edc => edc.id === dc.id)) {
        return;
      }
      newData.push(dc);
    })

    setList({ type: data.write_mode, payload: newData });
  }, [setList]);

  const getRowHeight = useCallback(
    (params) => {
      // Default row height
      let maxHeight = 50;
  
      // Iterate over columns with variableHeight: true
      columnDefs.forEach((col) => {
        if (col.variableHeight) {
          const field = col.field;
          const value = params.data[field];
  
          let contentHeight = 50; // Base height for the column
  
          if (Array.isArray(value)) {
            // Adjust height based on the number of items
            contentHeight = value.length * 45; // Adjust itemHeight as needed
          } else if (typeof value === 'string') {
            // Adjust height based on string length (optional)
            const lineHeight = 20; // Approximate line height
            const lines = Math.ceil(value.length / 50); // Approximate characters per line
            contentHeight = lines * lineHeight + 20; // Add padding
          }
          // Update maxHeight if this contentHeight is greater
          if (contentHeight > maxHeight) {
            maxHeight = contentHeight;
          }
        }
      });
  
      return maxHeight;
    },
    [columnDefs] // Dependency array for useCallback
  );
  

  return (
    <div className="mt-2 ag-theme-alpine ag-row grid-height-400" onContextMenu={onContextMenu} > 
        <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        suppressRowTransform={needsSuppressRowTransform}
        getRowHeight={getRowHeight}    
        onGridReady={onGridReady}
        context={agGridContext}
        onCellValueChanged={onCellValueChanged}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        onCellContextMenu={onCellContextMenu}
        onCellFocused={onCellFocused}
        />
        {contextMenu && (
        <AggridContextMenu
            buttonLabel={'Refine ' + listTitle}
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={closeContextMenu}
            onGenerate={() => {
            contextMenuGeneration(contextMenu.rowData).then(updatedDesignCriteria => {
                setList({type: 'replace', payload: {old: contextMenu.rowData, new: updatedDesignCriteria}});
                closeContextMenu();
            }).catch(error => {
                console.error('Error refining Design Criteria: ', error);
            });
            }}
            />
        )}
    </div>
  );
}

export default Table;
