import React, { useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Table from 'components/Table';

const CollapsibleEvaluationAgGrid = (props) => {
  const { buttonLabel, evaluation, coverage, listTitle, List, setList, columnDefs, rowData, contextMenuGeneration, isLoading } = props;
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <button onClick={toggleCollapse} className='container-wide-collapse-button'>
        {collapsed ? (
          'Evaluate' 
        ) : (
          'Hide Evaluation'
        )}
      </button>
      {!collapsed && (
        <>
        
          <div className="card__content-wrapper pd-2 mb-2">
            <h3>Strategy alignement</h3>
            <p>{evaluation}</p>
          </div>
          <div className="card__content-wrapper pd-2 mb-2">
            <h3>Strategy coverage</h3>
            <p>{coverage}</p>
          </div>
          {/*<p>{designCriteriaEvaluation[0]?.['alignment_evaluation']}</p>
          <p>{designCriteriaEvaluation[0]?.['coverage_evaluation']}</p>
          <p>{designCriteriaEvaluation[0]?.['missing_aspects']}</p>
          <p>Design Criteria Score: {designCriteriaEvaluation[0]?.['score']}</p>*/}
      
        
        
          <div
            className="ag-theme-alpine card__content-wrapper"
            style={{ height: 200, width: '100%', marginTop: 10 }}
          >
            <Table 
              listTitle={listTitle}
              List={rowData}
              setList={setList}
              columnDefs={columnDefs}
              rowData={rowData}
              contextMenuGeneration={contextMenuGeneration? contextMenuGeneration : null}
              isLoading={isLoading}
            />
            
          </div>
        </>
      )}
    </>
  );
};

export default CollapsibleEvaluationAgGrid;
