import React, { useEffect, useCallback, useState, useReducer } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  ButtonGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import ApiHelper from 'api/helper';
import { useDeepCompareEffect } from 'lib/useDeepCompare';
import Button from 'components/Button';
import DesignTitleModal from 'pages/Design/components/DesignTitleModal';
import MissionTab from './components/MissionTab';
import OrgChartTab from '../Describe/components/OrgChartTab';
import StrategyTab from './components/StrategyTab';
import DesignCriteriaTab from './components/DesignCriteriaTab';
import FunctionsTab from './components/FunctionsTab';
import { arrayReducer } from 'lib/helper';
import { set } from 'lodash';


const DefineIndex = (props) => {
  const [activeTab, setActiveTab] = useState('DesignCriteriaTab');
  const [defineModuleData, setDefineModuleData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [businessDetails, setBusinessDetails] = useState({
    industry:{industry:'',industry_group:'',sector:'',sub_industry:''},
    num_employees:''
  });
  const [missionStatement, setMissionStatement] = useState('');
  const [strategyArena, setStrategyArena] = useState('');
  const [strategyVehicle, setStrategyVehicle] = useState('');
  const [strategyDifferentiator, setStrategyDifferentiator] = useState('');
  const [strategyStaging, setStrategyStaging] = useState('');
  const [strategyEconomicLogic, setStrategyEconomicLogic] = useState('');
  const [designCriteria, setDesignCriteria] = useReducer(arrayReducer, []);
  const [functions, setFunctions] = useReducer(arrayReducer, []);
  const [organizationUnits, setOrganizationUnits] = useReducer(arrayReducer, []);
  const [roles, setRoles] = useReducer(arrayReducer, []);
  const [mandates, setMandates] = useReducer(arrayReducer, []);
  const navigate = useNavigate();

  useDeepCompareEffect(() => {
    if (!loading) {
      // This code runs when any of the dependencies have changed deeply, but only after the initial data has been loaded
      save(name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, designCriteria, organizationUnits, roles, mandates);
    }
  }, [name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, designCriteria, organizationUnits, roles, mandates]);

  useEffect(() => {
    if (!props.currentClient) return;

    ApiHelper.get(`/api/define/?client=${props.currentClient}`).then((defineObjectsList) => {
      const define_queryset = defineObjectsList.data[0];
      setDefineModuleData(define_queryset);
      if (define_queryset) {
        setName(define_queryset.business_name);
        setWebsite(define_queryset.business_website);
        setBusinessDetails(JSON.parse(define_queryset.business_details));
        setMissionStatement(define_queryset.mission_statement);
        setStrategyArena(define_queryset.strategy_arena);
        setStrategyVehicle(define_queryset.strategy_vehicle);
        setStrategyDifferentiator(define_queryset.strategy_differentiators);
        setStrategyStaging(define_queryset.strategy_staging);
        setStrategyEconomicLogic(define_queryset.strategy_economic_logic);
        setDesignCriteria({ type: 'overwrite', payload: define_queryset.design_criteria ? JSON.parse(define_queryset.design_criteria) : [] });
        setFunctions({ type: 'overwrite', payload: define_queryset.functions ? JSON.parse(define_queryset.functions) : [] });
        setOrganizationUnits({ type: 'overwrite', payload: define_queryset.organizationUnits ? JSON.parse(define_queryset.organizationUnits) : [] });
        setRoles({ type: 'overwrite', payload: define_queryset.roles ? JSON.parse(define_queryset.roles) : [] });
        setMandates({ type: 'overwrite', payload: define_queryset.mandates ? JSON.parse(define_queryset.mandates) : [] });
      } else {
        setName('');
        setWebsite('');
        setBusinessDetails(
          {
            industry:{industry:'',industry_group:'',sector:'',sub_industry:''},
            revenue:{revenue:'',currency:{name: '', currencyCode: '', countryCode: ''}},
            num_employees:''
          }
        );
        setMissionStatement('');
        setStrategyArena('');
        setStrategyVehicle('');
        setStrategyDifferentiator('');
        setStrategyStaging('');
        setStrategyEconomicLogic('');
        setDesignCriteria({ type: 'overwrite', payload: [] });
        setOrganizationUnits({ type: 'overwrite', payload: [] });
        setRoles({ type: 'overwrite', payload: [] });
        setMandates({ type: 'overwrite', payload: [] });
      }
      setLoading(false);
    });
  }, [props.currentClient]);

  const save = useCallback(async (name, website, businessDetails, businessDescription, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, designCriteria, organizationUnits, roles, mandates) => {
    const apiPayload = {
      id: defineModuleData?.id,
      client: props.currentClient,
      
      business_name: name,
      business_website: website,
      business_details: JSON.stringify(businessDetails),
      business_description: businessDescription,
      strategy_arena: strategyArena,
      strategy_vehicle: strategyVehicle,
      strategy_differentiator: strategyDifferentiator,
      strategy_staging: strategyStaging,
      strategy_economic_logic: strategyEconomicLogic,
      design_criteria: JSON.stringify(designCriteria),
      organization_units: JSON.stringify(organizationUnits),
      roles: JSON.stringify(roles),
      mandates: JSON.stringify(mandates),
    };

    if (defineModuleData?.id) {
      await ApiHelper.patch(`/api/define/${defineModuleData.id}/`, {
        data: apiPayload,
      });
      setDefineModuleData({
        ...apiPayload
      });

      return {
        ...apiPayload
      };
    } else {
      const { data: st } = await ApiHelper.post('/api/define/', {
        data: apiPayload,
      });
      setDefineModuleData(st);
      return st;
    }
  }, [name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, designCriteria, organizationUnits, roles, mandates]);

  const saveAndChangeTab = useCallback(
    (tab) => {
      save(name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, designCriteria, organizationUnits, roles, mandates).then(() => setActiveTab(tab));
    },
    [save, name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, designCriteria, organizationUnits, roles, mandates]
  );

  const onModalToggle = () => {
    setShowModal(!showModal);
  }

  const navigateToDescribeModule = () => {
    save(name, website, businessDetails, missionStatement, strategyArena, strategyVehicle, strategyDifferentiator, strategyStaging, strategyEconomicLogic, designCriteria, organizationUnits, roles, mandates).then(() => {
      navigate('/describe/', {
        state: {
          business_name: name,
          business_website: website,
        },
      });
    });
  };

  const createDesign = (title) => {
    // TODO: Add this API endpoint backend
    ApiHelper.post('/api/design-from-define/', {
      data: {
        title,
        define_id: defineModuleData.id,
        client: props.currentClient,
      },
    }).then((res) => {
      navigate('/design/org/' + res.data.id);
    });
  }


  if (!+props.currentClient) {
    return (
      <div style={{ textAlign: 'center' }} className="py-4">
        <h3>Select the organization from the dropdown at the top right of your screen to select which organization you want to work on</h3>
      </div>
    );
  }

  return (
    <div className="strategy-index">
      <div
        className="content__header py-4 d-lg-flex align-items-center justify-content-between">
        <h3 className="ellipsis">Define your organization</h3>
        <div className='strategy-tab-modal'>
          <Button
            className="button me-3 mb-3 w-auto"
            text="Create Design"
            onClick={onModalToggle}
          />
          <Button
            className="button me-3 mb-3 w-auto"
            text="Describe current organization"
            onClick={navigateToDescribeModule}
          />
        </div>
      </div>
      <div className="card__group">
        <div className="card">
          <div className="card__content-wrapper">
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={activeTab === 'BusinessProfileTab'}
                  onClick={() => saveAndChangeTab('BusinessProfileTab')}
                  className="strategy-tab">
                  Mission
                </NavLink>
              </NavItem>
             <NavItem>
              <NavLink
                active={activeTab === 'StrategyTab'}
                onClick={() => saveAndChangeTab('StrategyTab')}
                className="strategy-tab">
                Strategy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'DesignCriteriaTab'}
                onClick={() => saveAndChangeTab('DesignCriteriaTab')}
                className="strategy-tab">
                Design Criteria
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'FunctionsTab'}
                onClick={() => saveAndChangeTab('FunctionsTab')}
                className="strategy-tab">
                Functions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'UnitMandatesTab'}
                onClick={() => saveAndChangeTab('UnitMandatesTab')}
                className="strategy-tab">
                Organization Units/Mandates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'RolesTab'}
                onClick={() => saveAndChangeTab('RolesTab')}
                className="strategy-tab">
                Roles
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'OrganizationModelTab'}
                onClick={() => saveAndChangeTab('OrganizationModelTab')}
                className="strategy-tab">
                Operating model
              </NavLink>
            </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
            <TabPane tabId="BusinessProfileTab">
              <MissionTab
                client_id={props.currentClient}
                name={name}
                setName={setName}
                website={website}
                setWebsite={setWebsite}
                businessDetails={businessDetails}
                setBusinessDetails={setBusinessDetails}
                businessDescription={missionStatement}
                setBusinessDescription={setMissionStatement}
              />
              </TabPane>
              <TabPane tabId="StrategyTab">
                <StrategyTab
                  client={props.currentClient}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  setStrategyArena={setStrategyArena}
                  strategyVehicle={strategyVehicle}
                  setStrategyVehicle={setStrategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  setStrategyDifferentiator={setStrategyDifferentiator}
                  strategyStaging={strategyStaging}
                  setStrategyStaging={setStrategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  setStrategyEconomicLogic={setStrategyEconomicLogic}
                />
              </TabPane>
              <TabPane tabId="DesignCriteriaTab">
                <DesignCriteriaTab 
                  client={props.currentClient}
                  designCriteria={designCriteria}
                  setDesignCriteria={setDesignCriteria}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                />
              </TabPane>
              <TabPane tabId="FunctionsTab">
                <FunctionsTab 
                  client={props.currentClient}
                  functions={functions}
                  setFunctions={setFunctions}
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  missionStatement={missionStatement}
                  strategyArena={strategyArena}
                  strategyVehicle={strategyVehicle}
                  strategyDifferentiator={strategyDifferentiator}
                  strategyStaging={strategyStaging}
                  strategyEconomicLogic={strategyEconomicLogic}
                  designCriteria={designCriteria}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
        <DesignTitleModal
          showModal={showModal}
          defaultTitle={name + ' ' + new Date().getDate() + '.' + (new Date().getMonth()+1) + '.' + new Date().getFullYear()}
          onConfirm={createDesign}
          onToggle={onModalToggle}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currentClient: state.currentClient?.data
  };
};

export default connect(mapStateToProps)(DefineIndex);
