import React, { useEffect, useCallback, useState, useReducer } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  ButtonGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';

import ApiHelper from 'api/helper';
import { useDeepCompareEffect } from 'lib/useDeepCompare';
import Button from 'components/Button';
import DesignTitleModal from 'pages/Design/components/DesignTitleModal';
import { setSurvey } from 'redux/actions/survey';
import BusinessProfile from './components/BusinessProfile';
import ActivitiesTab from './components/ActivitiesTab';
import EmployeesTab from './components/EmployeesTab';
import OrgChartTab from './components/OrgChartTab';
import { arrayReducer } from 'lib/helper';

const replaceActivityAction = (activityToReplace, updatedActivities) => ({
  type: 'replace',
  payload: {
    activityToReplace,
    updatedActivities,
  }
});

const DescribeIndex = (props) => {
  const [activeTab, setActiveTab] = useState('BusinessProfileTab');
  const [describeModuleData, setDescribeModuleData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [businessDetails, setBusinessDetails] = useState({
    industry:{industry:'',industry_group:'',sector:'',sub_industry:''},
    revenue:{revenue:'',currency:{name: '', currencyCode: '', countryCode: ''}},
    num_employees:''
  });
  const [businessDescription, setBusinessDescription] = useState('');
  const [activities, setActivities] = useReducer(arrayReducer, []);
  const [employees, setEmployees] = useReducer(arrayReducer, []);
  const navigate = useNavigate();

  useDeepCompareEffect(() => {
    if (!loading) {
      // This code runs when any of the dependencies have changed deeply, but only after the initial data has been loaded
      save(name, website, businessDetails, businessDescription, activities, employees);
    }
  }, [name, website, businessDetails, businessDescription, activities, employees]);

  useEffect(() => {
    if (!props.currentClient) return;

    ApiHelper.get(`/api/describe/?client=${props.currentClient}`).then((describeObjectsList) => {
      const describe_queryset = describeObjectsList.data[0];
      setDescribeModuleData(describe_queryset);
      if (describe_queryset) {
        setName(describe_queryset.business_name);
        setWebsite(describe_queryset.business_website);
        setBusinessDetails(JSON.parse(describe_queryset.business_details));
        setBusinessDescription(describe_queryset.business_description);
        setActivities({ type: 'overwrite', payload: JSON.parse(describe_queryset.activities) });
        setEmployees({ type: 'overwrite', payload: JSON.parse(describe_queryset.employees) });
      } else {
        setName('');
        setWebsite('');
        setBusinessDetails(
          {
            industry:{industry:'',industry_group:'',sector:'',sub_industry:''},
            revenue:{revenue:'',currency:{name: '', currencyCode: '', countryCode: ''}},
            num_employees:''
          }
        );
        setBusinessDescription('');
        setActivities({ type: 'overwrite', payload: [] });
        setEmployees({ type: 'overwrite', payload: [] });
      }
      setLoading(false);
    });
  }, [props.currentClient]);

  const save = useCallback(async (name, website, businessDetails, businessDescription, activities, employees) => {
    const apiPayload = {
      id: describeModuleData?.id,
      client: props.currentClient,
      
      business_name: name,
      business_website: website,
      business_details: JSON.stringify(businessDetails),
      business_description: businessDescription,
      activities: JSON.stringify(activities),
      employees: JSON.stringify(employees),
    };

    if (describeModuleData?.id) {
      await ApiHelper.patch(`/api/describe/${describeModuleData.id}/`, {
        data: apiPayload,
      });
      setDescribeModuleData({
        ...apiPayload
      });

      return {
        ...apiPayload
      };
    } else {
      const { data: st } = await ApiHelper.post('/api/describe/', {
        data: apiPayload,
      });
      setDescribeModuleData(st);
      return st;
    }
  }, [describeModuleData, setDescribeModuleData, props.currentClient]);

  const saveAndChangeTab = useCallback(
    (tab) => {
      save(name, website, businessDetails, businessDescription, activities, employees).then(() => setActiveTab(tab));
    },
    [save, name, website, businessDetails, businessDescription, activities, employees],
  );

  const onModalToggle = () => {
    setShowModal(!showModal);
  }

  const createSurvey = () => {
    save(name, website, businessDetails, businessDescription, activities, employees).then(() => {
      const surveyActivities = activities.map((activity, index) => ([
        activity.function,
        activity.activity,
      ]));

      const participants = employees.map((employee) => [
        employee.first_name,
        employee.last_name,
        employee.role, // Assuming 'role' is the correct key for the title
        employee.fte || 1,
        employee.organization_unit, // Assuming this is the department
        employee.location || '',
        employee.email,
      ]);
      

      navigate('/discover/create', {
        state: {
          describeModuleData: {
            name,
            activities: surveyActivities,
            participants
          },
          survey: {
            name,
            data: {
              activities: surveyActivities,
              participants,
            }
          },
          name,
          activities: surveyActivities,
          participants,
        },
      });
    });
  };

  const createDesign = (title) => {
    ApiHelper.post('/api/design-from-describe/', {
      data: {
        title,
        describe_id: describeModuleData.id,
        client: props.currentClient,
      },
    }).then((res) => {
      navigate('/design/org/' + res.data.id);
    });
  }


  if (!+props.currentClient) {
    return (
      <div style={{ textAlign: 'center' }} className="py-4">
        <h3>Select the organization from the dropdown at the top right of your screen to select which organization you want to work on</h3>
      </div>
    );
  }

  return (
    <div className="strategy-index">
      <div
        className="content__header py-4 d-lg-flex align-items-center justify-content-between">
        <h3 className="ellipsis">describe your current organization</h3>
        <div className='strategy-tab-modal'>
          <Button
            className="button me-3 mb-3 w-auto"
            text="Create Design"
            onClick={onModalToggle}
          />
          <Button
            className="button me-3 mb-3 w-auto"
            text="Create Discover Survey"
            onClick={createSurvey}
          />
        </div>
      </div>
      <div className="card__group">
        <div className="card">
          <div className="card__content-wrapper">
            <Nav tabs>
            <NavItem>
                <NavLink
                  active={activeTab === 'BusinessProfileTab'}
                  onClick={() => saveAndChangeTab('BusinessProfileTab')}
                  className="strategy-tab">
                  Business profile
                </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'ActivitiesTab'}
                onClick={() => saveAndChangeTab('ActivitiesTab')}
                className="strategy-tab"
                disabled={activeTab === 'ActivitiesTab'}>
                Activities
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'EmployeesTab'}
                onClick={() => saveAndChangeTab('EmployeesTab')}
                className="strategy-tab"
                disabled={activeTab === 'EmployeesTab'}>
                Employees
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'orgChart'}
                onClick={() => saveAndChangeTab('orgChart')}
                className="strategy-tab"
                disabled={activeTab === 'orgChart'}>
                Organization chart
              </NavLink>
            </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="BusinessProfileTab">
                <BusinessProfile
                  name={name}
                  setName={setName}
                  website={website}
                  setWebsite={setWebsite}
                  businessDetails={businessDetails}
                  setBusinessDetails={setBusinessDetails}
                  businessDescription={businessDescription}
                  setBusinessDescription={setBusinessDescription}
                />
              </TabPane>
              <TabPane tabId="ActivitiesTab">
                <ActivitiesTab
                  name={name}
                  website={website}
                  businessDetails={businessDetails}
                  businessDescription={businessDescription}
                  activities={activities}
                  setActivities={setActivities}
                  replaceActivityAction={replaceActivityAction}
                />
              </TabPane>
              <TabPane tabId="EmployeesTab">
                <EmployeesTab
                  name={name}
                  website={website}
                  businessDescription={businessDescription}
                  businessDetails={businessDetails}
                  activities={activities}
                  employees={employees}
                  setEmployees={setEmployees}
                  tabType="DESCRIBE"
                />
              </TabPane>
              <TabPane tabId="orgChart">
                <OrgChartTab
                  name={name}
                  website={website}
                  businessDescription={businessDescription}
                  industry={businessDetails?.industry}
                  activities={activities}
                  employees={employees}
                  setEmployees={setEmployees}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
        <DesignTitleModal
          showModal={showModal}
          defaultTitle={name + ' ' + new Date().getDate() + '.' + (new Date().getMonth()+1) + '.' + new Date().getFullYear()}
          onConfirm={createDesign}
          onToggle={onModalToggle}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currentClient: state.currentClient?.data
  };
};

export default connect(mapStateToProps)(DescribeIndex);
