import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Loading from 'components/Loading';

function objectsAreNotEqual(obj1, obj2) {
  if (obj1 === undefined || obj2 === undefined) {
    return true;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return true;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }

  return false;
}

const DraggableListCellRenderer = (props) => {
  const { isLoading, prevRowData } = props.context;
  const { value, data, column } = props;
  const [items, setItems] = useState(value || []);
  const [isCellBlinking, setIsCellBlinking] = useState(false);

  useEffect(() => {
    if (prevRowData && prevRowData.length > 0) {
      const prevData = prevRowData.find((row) => row.id === data.id);
      if (objectsAreNotEqual(data, prevData)) {
        setIsCellBlinking(true);
        const timer = setTimeout(() => setIsCellBlinking(false), 10000);
        return () => clearTimeout(timer);
      }
    }
  }, [data, prevRowData]);

  useEffect(() => {
    setItems(value || []);
  }, [value]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    setItems(newItems);

    // Update the data in the grid
    const field = column.getColDef().field;
    const updatedData = { ...data, [field]: newItems };
    props.node.setData(updatedData);
  };

  if (isLoading?.includes(data?.id)) {
    return (
      <div className="loading-spin">
        <Loading size="small" />
      </div>
    );
  } else {
    return (
      <div
        className={isCellBlinking ? 'gradient-animated-text' : ''}
        style={{ whiteSpace: 'normal', lineHeight: '1.5', padding: '0', overflow: 'visible' }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`droppable-${data.id}-${column.getColId()}`}>
                {(provided, snapshot) => (
                    <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                        background: snapshot.isDraggingOver ? '#f0f0f0' : 'white',
                        padding: 0,
                        margin: 0,
                        minHeight: '50px', // Ensure minimum height
                    }}
                    >
                    {items.map((item, index) => (
                        <Draggable
                        key={item.id || `item-${index}`}
                        draggableId={`${item.id || `item-${index}`}`}
                        index={index}
                        >
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                userSelect: 'none',
                                padding: '8px',
                                margin: '0 0 4px 0',
                                backgroundColor: snapshot.isDragging ? '#e0e0e0' : '#f9f9f9',
                                color: 'black',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                ...provided.draggableProps.style,
                            }}
                            >
                            {item.name || item}
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
      </div>
    );
  }
};

export default DraggableListCellRenderer;
