import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader, Container, Col, Row } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import StrategicActivityClassificationEditor from 'components/StrategicActivityClassificationEditor';
import ExportToExcel from 'lib/ExportToExcel';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ArrayCellRenderer from 'components/ArrayCellRenderer';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';
import Table from 'components/Table';

const FunctionsTab = (props) => {
  const { client, functions, setFunctions, designCriteria,
    name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic } = props;

  const [isGeneratingFunctions, setIsGeneratingFunctions] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);

  const prevRowDataRef = useRef();

  const baseColumnConfig = {
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  }

  const columnDefs = [
    { headerName: 'ID', field: 'id', hide: true, ...baseColumnConfig},
    { headerName: '', field: 'emoji', ...baseColumnConfig, width: 30, flex: 0 },
    { headerName: 'Name', field: 'name', cellRendererFramework: LoadingCellRenderer, ...baseColumnConfig },
    { headerName: 'Description', field: 'description', cellRendererFramework: LoadingCellRenderer, wrapText: true, ...baseColumnConfig, flex: 4},
    { headerName: 'Objective', field: 'objective', cellRendererFramework: LoadingCellRenderer, wrapText: true, ...baseColumnConfig, flex: 2},
    { headerName: 'Classification', field: 'classification', cellRendererFramework: StrategicActivityClassificationEditor, ...baseColumnConfig},
    { headerName: 'Inputs', field: 'inputs', cellRendererFramework: DraggableListCellRenderer, ...baseColumnConfig, variableHeight: true},
    { headerName: 'Outputs', field: 'outputs', cellRendererFramework: DraggableListCellRenderer, ...baseColumnConfig, variableHeight: true,},
    { headerName: '', field: 'delete_row', cellRenderer: params => <DeleteRowButtonRenderer {...params} list={functions} setList={setFunctions} />, cellClass: 'delete-row-button-cell', width: 50, flex: 0 }
  ];
  
  const rowData = useMemo(() => {
    if (Array.isArray(functions)) {
      return functions.map(functions => ({
        id: functions.id,
        name: functions.name,
        description: functions.description,
        objective: functions.objective,
        inputs: functions.inputs,
        outputs: functions.outputs,
        emoji: functions.emoji,
      }));
    }
    return [];
  }, [functions]);

  const addNewRow = () => {
    const newRow = {
      id: generateuniqueIshId(),
      name: `Function ${Array.isArray(functions) ? functions.length + 1 : 1}`,
      designCriteria: '',
      priority: ''
    };
    setFunctions({type: 'prepend', payload: [newRow] });
    return newRow;
  };

  const DeleteRowButtonRenderer = ({ api, node, data, list, setList }) => {
    const handleDelete = () => {
      const updatedList = list.filter(f => f.id !== data.id);
      setList({ type: 'overwrite', payload: updatedList });
  
      setTimeout(() => {
        api.setRowData(updatedList);
      }, 0);
    };
  
    return (
      <span onClick={handleDelete}>
        <SVG
          icon="delete.svg#delete"
          iconWidth={24}
          iconHeight={24}
          iconClassName="button__icon button__icon-red"
        />
      </span>
    );
  };

  const generateFunctions = async (
  ) => {
    if (isGeneratingFunctions) return;
    setIsGeneratingFunctions(true);
    const blankRow = addNewRow();
    setIsLoading({ type: 'add_to_list', payload: blankRow.id });
    
    setIsGeneratingFunctions(true);

    const apiPayload = {
        'prompt_template': 'generate_functions',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'functions': functions ? functions : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedFunctions = apiResponse.message;

      try {
        setFunctions({ type: 'prepend', payload: generatedFunctions } );
        setIsGeneratingFunctions(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsGeneratingFunctions(false);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsGeneratingFunctions(false);
    setIsLoading({ type: 'remove_from_list', payload: blankRow.id });
    setFunctions({ type: 'remove', payload: blankRow });
  };

  const refineFunctions = async (activity) => {
    return; 
    /*console.log('Splitting activity:', activity);
    setIsLoading({ type: 'add_to_list', payload: [activity.id] });
    const apiPayload = {
      'prompt_template': 'split_design_criteria',
      'activity_to_split': activity.activity,
      'description': activity.description,
      'business_name': name ? name : '', 
      'business_website': website ? website : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'num_employees': businessDetails.num_employees ? businessDetails.num_employees : '',
      'revenue': businessDetails.revenue ? getRevenueFormated(businessDetails.revenue) : '',
      'designCriteria': designCriteria ? designCriteria : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const newdesignCriteria = apiResponse.message[0]['designCriteria'];
      return newdesignCriteria;
    } catch (error) {
      console.error('Error sending chat message:', error);
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: [activity.id] });
    }*/
  }

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newFunctions = [];
    data.rows.forEach((row) => {
      let newRow = {
        id: generateuniqueIshId(),
        name: data.name,
        description: row[data.description],
        objective: row[data.objective],
        classification: row[data.classification],
        inputs: row[data.inputs],
        outputs: row[data.outputs],
      }
      if (newFunctions.find(f => f.id === newRow.id)) {
        return;
      }
      newFunctions.push(newRow);
    })

    setFunctions({ type: data.write_mode, payload: newFunctions });
  }, [setFunctions]);

  return (
    <div className="function-tab">
      <div className="function-tab-header">
      </div>
      
      <Row xs="8" className="align-items-left">
        <Col>
          <button onClick={() => generateFunctions()} disabled={isGeneratingFunctions} className="button ms-auto">
            { functions.length !== 0 ? 'Generate more functions' : 'Generate Functions' }
            <span className="material-symbols-outlined">
              neurology
            </span>
          </button>
          <button onClick={() => setShowImport(true)} className="button ms-auto">
            Import Functions
            <span className="material-symbols-outlined">
              upload
            </span>
          </button>
          <ExportToExcel 
            buttonLabel='Export Functions' 
            data={functions} 
            fileName={name + ' Functions from Reconfig'} 
            sheetName={name + ' Functions'} 
            fieldsToExport={['name', 'description', 'objective', 'classification', 'inputs', 'outputs']}
          />
          <Button
            color="primary"
            onClick={addNewRow}
            text="Add Function"
            className="button ms-auto"
            icon="plus.svg#plus"
          />
          <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
            <ModalHeader>Import Design Criteria</ModalHeader>
            <CSVWizard
              toggle={() => setShowImport(false)}
              handleSubmit={handleImportSubmit}
              specifyColumns={[
                { key: 'name', label: 'Name', placeholder: 'Name', },
                { key: 'description', label: 'description', placeholder: 'Design Criteria' },
                { key: 'objective', label: 'Objective', placeholder: 'Objective' },
                { key: 'classification', label: 'Classification', placeholder: 'Classification' },
                { key: 'inputs', label: 'Inputs', placeholder: 'Inputs' },
                { key: 'outputs', label: 'Outputs', placeholder: 'Outputs' },
              ]}
            />
          </Modal>
        </Col>
      </Row>
        <Table
          listTitle="Functions"
          List={functions}
          setList={setFunctions}
          columnDefs={columnDefs}
          rowData={rowData}
          contextMenuGeneration={refineFunctions}
          isLoading={isLoading}
        />
    </div>
  );
}

export default FunctionsTab;
