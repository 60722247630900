import React from 'react';
import { Route, Routes, Outlet, Navigate, useLocation } from 'react-router-dom';

//Layouts
import Login from 'pages/login/Login';
import Header from 'pages/layout/header/Header';
import Sidebar from 'pages/layout/sidebar/Sidebar';
import Diagnoses from 'pages/Diagnose';
import NewDiagnose from 'pages/Diagnose/New';
import DiagnosisDetail from 'pages/Diagnose/Detail';
import DescribeIndex from 'pages/Describe/DescribeIndex';
import DefineIndex from 'pages/Define/DefineIndex';
import SurveyCreate from 'pages/Survey/SurveyCreate';
import SurveyIndex from 'pages/Survey/SurveyIndex';
import SurveyShow from 'pages/Survey/SurveyShow';
import Design from 'pages/Design';
import DesignOrg from 'pages/Design/DesignOrg';
import Profile from 'pages/Profile';
import EmailVerification from 'pages/Profile/EmailVerification';
import PasswordReset from 'pages/PasswordReset';
import { useSelector } from 'react-redux';
import { isAuthenticated } from 'redux/reducers/auth';
import classNames from 'classnames';

const Router = () => {
  const authenticated = useSelector(s => isAuthenticated(s.auth));
  const location = useLocation();
  const { is_new } = useSelector(state => state.profile?.data || {});
  const collapse = useSelector(state => state.sidebar.collapse)

  if (!authenticated && location.pathname !== '/login') {
    return <Navigate to="/login" />
  }

  if (is_new === true) {
    return (
      <div className={classNames({
        content: true,
        navbarToggled: collapse,
      })}>
        <Routes>
          <Route path="/" element={<Navigate to="/reset-password" />} />
          <Route exact path="/reset-password" element={<PasswordReset />} />
          <Route path="*" element={<Navigate to="/reset-password" />} />
        </Routes>
      </div>
    );
  }

  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/logout" element={<Login />} />
      <Route path="/" element={<WrappedRoutes />}>
        <Route exact path="/" element={<Navigate to="/design" />} />
        <Route exact path="/diagnose" element={<Diagnoses />} />
        <Route exact path="/diagnose/:id" element={<DiagnosisDetail />} />
        <Route exact path="/diagnose/new" element={<NewDiagnose />} />
        <Route exact path="/describe" element={<DescribeIndex />} />
        <Route exact path="/define" element={<DefineIndex />} />
        <Route exact path="/discover" element={<SurveyIndex />} />
        <Route exact path="/discover/create" element={<SurveyCreate />} />
        <Route exact path="/discover/create/:survey" element={<SurveyCreate />} />
        <Route exact path="/discover/:survey" element={<SurveyShow />} />
        <Route exact path="/design" element={<Design />} />
        <Route exact path="/design/org/:design" element={<DesignOrg />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/profile/verify" element={<EmailVerification />} />
      </Route>
    </Routes>
  )
};

const WrappedRoutes = () => {
  const collapse = useSelector(state => state.sidebar.collapse)
  return (
    <div>
      <Header />
      <div className="plat-content">
        <Sidebar />
        <div className={classNames({
          content: true,
          navbarToggled: collapse,
        })}>
          <Outlet />
        </div>
      </div>
    </div >
  );
};

export default Router;
